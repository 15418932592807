<template>
  <div class="messages">
    <div class="content">
      <div class="header">
        <el-input v-model.trim="query.title" clearable @clear="handleSearch" placeholder="请输入内容关键字" style="width: 400px;">
          <el-button slot="append" @click="handleSearch" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <table-with-pagination
        :tableData="list"
        class="content_block"
        :columnList="columnList"
        :pagination.sync="pagination"
        @current-change="getTableList"
        @sort-change="getTableList"
      />
    </div>
    <notice-detail-dialog ref="noticeDetailDialogRef" :detail="currentItem" />
  </div>
</template>
<script>
import { announcement } from '@/api/messages';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { dateFormat } from '@/utils/dateFormat.js';
import NoticeDetailDialog from './Components/noticeDetailDialog.vue';
export default {
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    NoticeDetailDialog,
  },
  data () {
    return {
      query: {
        title: '',
      },
      columnList: [
        {
          tooltip: true,
          label: '公告类型',
          width: '150px',
          prop: 'typeName',
        },
        {
          tooltip: true,
          label: '公告标题',
          width: '320px',
          prop: 'title',
          render: (h, {row})=>{
            let clickFn = this.handleActionClick.bind(this, row);
            let classObj = {'text-primary': true, 'cursor-pointer': true};

            return <span class={classObj} onClick={clickFn}>{row.title}</span>;
          },
        },
        {
          tooltip: true,
          label: '公告内容',
          prop: 'contentText',
        },
        {
          tooltip: true,
          label: '创建时间',
          width: '180px',
          prop: 'createTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
      list: [],
      currentItem: {},
      dialogVisible: false,
    };
  },
  methods: {
    handleActionClick (item) {
      this.currentItem = item;
      this.$refs.noticeDetailDialogRef.dialogVisible = true;
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.list = [];
      this.getTableList();
    },
    async getTableList () {
      let params = {
        ...this.query,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status: 'PUBLISHED',
        orderBy: [
          {
            orderByProperty: 'lastUpdateTime',
            orderByType: 'DESC',
          },
        ],
      };
      const data = await announcement(params);
      this.list = (data.body.list || []).map(item => {
        item.contentText = item.content.replace(/(<[/]?p>)|(&nbsp;)/g, '');
        return item;
      });
      this.pagination.total = Number(data.body.total) || 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.messages {
  background-color: #F2F4F7;
  .content {
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 75px;
    padding: 15px 0;
    .header {
      display: flex;
      align-items: center;
      padding: 0 15px 0;
    }
  }
}
</style>
